import * as R from 'ramda'

import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import React from 'react'

import {
  allContentfulLandingPagePropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import SEO from 'components/seo'

import '../styles/landingPage.css'

const LandingPage = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const {title, iFrameUrl, height} = R.pathOr(
    null,
    ['allContentfulLandingPage', 'nodes', 0],
    data,
  )

  const iFrameTemplate = `<iframe width="100%" height=${height} id="iframe" src=${iFrameUrl} title=${title} frameborder="0" clipboard-write; allowfullscreen></iframe>`

  const createMarkup = () => ({__html: iFrameTemplate})

  const pageMetaData = {
    metaTitle: 'iframe-title',
    metaDescription: 'iframe-description',
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div
        className="landingContainer"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={createMarkup()}
      />
    </>
  )
}

LandingPage.propTypes = {
  data: shape({
    allContentfulLandingPage: allContentfulLandingPagePropTypes,
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export default LandingPage

export const pageQuery = graphql`
  query templateLandingPage($nodeLocale: String, $technicalName: String) {
    allContentfulLandingPage {
      nodes {
        title
        iFrameUrl
        height
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
